<template>
  <transition name="scaleY">
    <div id="SearchSupply" v-if="keywordList.length" :class=style>
      <button v-if="showLeft" :class="$style.left" @click="scrollAni(-1)">
        <svg :class="$style.arrow" height="16" width="16" viewBox="0 0 24 24" aria-label="Left arrow" role="img"><path d="M17.28 24c-.57 0-1.14-.22-1.58-.66L4.5 12 15.7.66a2.21 2.21 0 0 1 3.15 0c.87.88.87 2.3 0 3.18L10.79 12l8.06 8.16c.87.88.87 2.3 0 3.18-.44.44-1 .66-1.57.66"></path></svg>
      </button>
      <button v-if="showRight" :class="$style.right" @click="scrollAni(1)">
        <svg :class="$style.arrow" height="16" width="16" viewBox="0 0 24 24" aria-label="Right arrow" role="img"><path d="M6.72 24c.57 0 1.14-.22 1.57-.66L19.5 12 8.29.66c-.86-.88-2.27-.88-3.14 0-.87.88-.87 2.3 0 3.18L13.21 12l-8.06 8.16c-.87.88-.87 2.3 0 3.18.43.44 1 .66 1.57.66"></path></svg>
      </button>

      <div :class="$style.wrapper" ref="wrapper">
        <router-link
            :aria-label="`搜索 ${item.term}`"
            :title="`搜索 ${item.term}`"
            v-for="(item,index) in keywordList"
            :class="$style.word"
            :key="index"
            :style="{ backgroundColor:item['dominant_color'] }"
            :to="{path:'/tupian', query:{kw: item.term }}"
            @click.native="wrapperClick(item.display)"
        >
          <span :class="$style.text">{{item.display}}</span>
        </router-link>
      </div>
    </div>
  </transition>
</template>
<script lang=ts>

  import TWEEN from "@tweenjs/tween.js";
  import { statsDzActionClick } from "bbw-common";
  /** 推荐词 */
  export default {
      name:"SearchSupply",
      props:{
          keywordList:{
              type:Array,
              required:true
          },
          ready:{
              type:Boolean,
              required:true
          }
      },
      mounted() {
          requestAnimationFrame(this.animate);
          this.url = window.location.href
      },
      activated() {
          requestAnimationFrame(this.animate);
      },
      deactivated() {
          cancelAnimationFrame(this.animate)
      },
      destroyed() {
          cancelAnimationFrame(this.animate)
      },
      data(){
          return {
              showLeft: false,
              showRight:false,
              url: ``
          }
      },
      methods:{
          animate(time){
              const el = this.$refs.wrapper as HTMLElement;
              if(el?.scrollLeft>0){
                  this.showLeft = true;
              }else {
                  this.showLeft = false;
              }
              if(el?.scrollLeft + el?.clientWidth >= el?.scrollWidth){
                  this.showRight = false;
              }else{
                  this.showRight = true;
              }
              requestAnimationFrame(this.animate);
              TWEEN.update(time);
          },
          scrollAni( direction ){
              const el = this.$refs.wrapper as HTMLElement;
              const startX:number = el?.scrollLeft;
              const transform = {
                  x: startX
              }
              const distance = document.body.clientWidth * 0.33;
              const tween = new TWEEN.Tween(transform)
                  .to({ x:startX+ (direction * distance) }, 1000)
                  .easing(TWEEN.Easing.Quartic.InOut)
                  .onUpdate( ()=>{
                      el.scrollTo({ left: transform.x, top:0 })
                  }).start()
          },
        //联想词点击统计
        wrapperClick(term){
          _hmt.push(['_trackEvent', 'PL原站推荐', '点击', '联想词'])
          //this.$router.push({path: "/tupian", query: {kw: term}});
          //this.disableSearch();
          statsDzActionClick ( {
            store:this.$store,
            action_type: `PL色块`,
            source: `pc`,
            from_page: this.url,
            remark: this.$route.query.kw
          } ) ;
        }
      },
      computed:{
          style () {
              return [this.$style.block, this.ready ? this.$style.ready : null]
          }
      }
  }
</script>
<style lang=scss module src="./SearchSupply.scss"></style>
