var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.$style.grid,
      on: {
        "!click": function ($event) {
          $event.stopPropagation()
          return _vm.handleClick.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { class: _vm.$style.btns }, [
        _c("div", { class: [_vm.$style.fav, _vm.$style.wateButton] }, [
          _vm._v("收藏"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { class: [_vm.$style.free, _vm.$style.wateButton, "font-bold"] },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "16",
                  height: "14",
                  viewBox: "0 0 16 14",
                  xmlns: "http://www.w3.org/2000/svg",
                  "svg-inline": "",
                  role: "presentation",
                  focusable: "false",
                  tabindex: "-1",
                },
              },
              [
                _c("path", {
                  attrs: {
                    d: "M14.222 7.93v4.333H1.778V7.93H0v6.067h16V7.93h-1.778zM8 10l3.556-4.167H8.889V0H7.11v5.833H4.444L8 10z",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
            _vm._v("\n        免费下载\n      "),
          ]
        ),
        _vm._v(" "),
        _vm.item.domain
          ? _c("a", { class: [_vm.$style.link, _vm.$style.wateButton] }, [
              _c("img", {
                class: _vm.$style.linkIcon,
                attrs: { src: _vm.process.env.CDN_DZ_PATH + "/p/pin_link.svg" },
              }),
              _vm._v(" "),
              _c("span", { class: _vm.$style.linkText }, [
                _c("span", [_vm._v(_vm._s(_vm.item.domain))]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { class: [_vm.$style.share, _vm.$style.wateButton] }, [
          _c("img", {
            class: _vm.$style.shareIcon,
            attrs: { src: _vm.process.env.CDN_DZ_PATH + "/p/pin_share.svg" },
          }),
        ]),
        _vm._v(" "),
        _c("div", { class: [_vm.$style.more, _vm.$style.wateButton] }, [
          _c("img", {
            class: _vm.$style.shareIcon,
            attrs: { src: _vm.process.env.CDN_DZ_PATH + "/p/pin_more.svg" },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.$style.imgWrapper, style: _vm.itemStyle }, [
        _c("div", { class: _vm.$style.imgCropper }, [
          _c("img", {
            class: _vm.$style.img,
            style: {
              opacity: _vm.loaded ? 1 : 0,
              width: _vm.setImgWidth(_vm.item),
            },
            attrs: { alt: _vm.item.title, src: _vm.dataSrc },
            on: {
              load: function ($event) {
                return _vm.handleImageLoaded($event)
              },
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }