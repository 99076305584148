var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.$style.wechatLogin }, [
    _c("div", { class: _vm.$style.qrcodeBox }, [
      _c("div", { ref: "qrcodeBox", class: _vm.$style.qrcode }, [
        _c("canvas", { ref: "qrcode" }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "refreshMask",
          class: _vm.$style.refresh,
          on: { click: _vm.refresh },
        },
        [_vm._v("刷新")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { class: [_vm.$style.loginDes, "font-medium"] }, [
      _c("span", { class: _vm.$style.wechatIcon }),
      _c("span", [_vm._v("微信扫码 - 关注公众号注册")]),
    ]),
    _vm._v(" "),
    _vm.isShowGoNewLogin
      ? _c(
          "div",
          {
            class: [_vm.$style.goLogin, "font-regular"],
            on: { click: _vm.goNewLogin },
          },
          [_c("span", [_vm._v("已有账号？")]), _c("span", [_vm._v("直接登录")])]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { class: [_vm.$style.loginExplain, "font-regular"] }, [
      _vm._v("若继续，则表示你同意 " + _vm._s(_vm.webSiteName) + " 的"),
      _c(
        "a",
        {
          class: ["font-medium"],
          attrs: { target: "_blank", href: _vm.statementUrl },
        },
        [_vm._v("网站声明")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }