<style lang="scss" module src="./Login.scss">
</style>

<template>
  <transition name="fade">
    <div @click.self="closeDialog" :class="[ $style.loginView, registerText === '注册后，立即使用扩大图' ? $style.loginView_Ai : '']"
    v-show="show" ref="loginView">
      <div :class="$style.closeDialog" @click.stop="closeDialog"></div>
      <div :class="$style.loginWrapper">
        <div :class="[$style.characteristic, registerText === '立即找软件' ? $style.softBg : '',  'font-regular']">
          <div :class="$style.wrapperTop">
            <h3 v-if="registerText !== '立即找软件'" :class="[$style.title]">欢迎来到大作</h3>
            <h5 :class="[$style.subtitle]">{{ registerText }}</h5>
            <ul :class="$style.characterList">
              <li v-for="(item, index) in list" :key="index" :class="[$style.item, 'font-regualr']">
                <span v-if="item.icon"></span>{{item.text}}
              </li>
            </ul>
          </div>
          <div v-if="registerText === '注册后，立即使用扩大图'" :class="$style.secondaryTitle">
            <span>找灵感</span>  <span>上大作</span>
          </div>

          <div v-else :class="$style.secondaryTitle">找灵感  上大作</div>
        </div>
        <div :class="[$style.loginBox,isNewLogin?$style.newLoginCss:'', registerText === '立即找软件' ? $style.softBox : '' ]" v-if="!$store.state.isServer">
          <div :class="$style.iconBox">
            <!-- <img :src="`${process.env.CDN_DZ_PATH}/bbw/wxregister3.svg`" alt="">
            <div class="" v-if="!isNewLogin">
              <p :class="$style.subTitle">找灵感  上大作</p>
              <p :class="$style.userNum">{{userNum}}万设计师正在使用</p> -->
            <div v-if="registerText === '立即找软件'" :class="[$style.rightH2, 'font-bold']">
                1000+设计必备专业软件库
            </div>
            <img v-else  :src="`${process.env.CDN_DZ_PATH}/bbw/wxregister3.svg`" alt="">
            <div :class="[registerText === '立即找软件' ? $style.softBlock : '']" v-if="!isNewLogin">
              <p v-if="registerText !== '立即找软件'" :class="$style.subTitle">找灵感  上大作</p>
              <p :class="[$style.userNum, 'font-regular']">{{userNum}}万设计师正在使用</p>
              <p v-if="registerText === '立即找软件'" :class="$style.rightH3">
                <span :class="['font-medium']">设计必备专业软件</span><span :class="['font-bold']">免费下载</span>
              </p>
            </div>
            <div v-else>
              <slot :userNum="userNum"></slot>
            </div>
          </div>
          <component :isShowGoNewLogin="isShowGoNewLogin" :webSiteName="webSiteName" :statementUrl="statementUrl" :pageUrl="pageUrl" :is="loginMode" :statusShow="show"></component>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Cookies from "js-cookie";
import WechatLogin from './WechatLogin.vue'
import { getScrollbarWidth,BigBigWork } from 'bbw-common'
export default {
  name: "login",
  components: { WechatLogin },
  props: {
    pageUrl: {
      default: '',
      type: String,
      required: false
    },
    show: {
      default: false,
      type: Boolean,
      required: false
    },
    registerText:{
      type: String,
      default: '注册后，立即找灵感'
    },
    list: {
      type: Array,
      default: () => {
        const list = [{
                icon: true,
                text: '5.7亿全球优秀设计作品'
              },{
                icon: true,
                text: '全球Top100设计网站'
              },{
                icon: true,
                text: '3千万免费版权高清图片'
              },{
                icon: true,
                text: '12个独特的设计灵感工具'
              },{
                icon: true,
                text: '在AI大作，创建AI艺术，'
              },{
                icon: false,
                text: '分享你的最佳创意'
              },
              /*{
                  icon: true,
                  text: '免工具使用Pinterest、'
                },{
                  icon: false,
                  text: 'Behance、Artstation等'
                }*/]
        return list
      }
    },
    isNewLogin:{
      default: false,
      type: Boolean,
      required: false
    },
    statementUrl: {
      default: `https://www.bigbigwork.com/webstatement.html`,
      type: String,
      required: false
    },
    webSiteName: {
      default: `大作`,
      type: String,
      required: false
    },
    // 是否显示  (已有账号 直接登录)
    isShowGoNewLogin:{
      default:false,
      type:Boolean,
      required:false
    }
  },
  data: function () {
    return {
      loginMode: '',
      // list: [{
      //   icon: true,
      //   text: '5.7亿全球优秀设计作品'
      // },{
      //   icon: true,
      //   text: '全球Top100设计网站'
      // },{
      //   icon: true,
      //   text: '3千万免费版权高清图片'
      // },{
      //   icon: true,
      //   text: '12个独特的设计灵感工具'
      // },{
      //   icon: true,
      //   text: '免工具使用Pinterest、'
      // },{
      //   icon: false,
      //   text: 'Behance、Artstation等'
      // }],
      userNum: ''
    }
  },
  async mounted() {
    (require as any).ensure([],  () => {
      const Wechat = require('./WechatLogin.vue')
      this.loginMode =Wechat.default
      let number = Cookies.get("number") || "7806100";
      let numstr = parseInt(number).toString();
      this.userNum = numstr.substring(0,numstr.length-4);
    })
  },
  methods: {
    closeDialog() {
      BigBigWork.emit('closeLoginView')
      this.$emit('closeLoginView')
      this.$emit('close')
    }
  },
  watch: {
    show: function (newV) {
      const scrollBarWidth = getScrollbarWidth()
      if(newV === true){
        this.showIframe = true;
        window.document.body.style.overflowY = 'hidden';
        window.document.body.style.paddingRight = `${scrollBarWidth}px`;
        this.$refs.loginView.style.paddingRight = `${scrollBarWidth}px`
      }else{
        window.document.body.style.overflowY = '';
        window.document.body.style.paddingRight = '';
      }
    }
  }
}
</script>


