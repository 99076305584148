var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "scaleY" } }, [
    _vm.keywordList.length
      ? _c("div", { class: _vm.style, attrs: { id: "SearchSupply" } }, [
          _vm.showLeft
            ? _c(
                "button",
                {
                  class: _vm.$style.left,
                  on: {
                    click: function ($event) {
                      return _vm.scrollAni(-1)
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      class: _vm.$style.arrow,
                      attrs: {
                        height: "16",
                        width: "16",
                        viewBox: "0 0 24 24",
                        "aria-label": "Left arrow",
                        role: "img",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M17.28 24c-.57 0-1.14-.22-1.58-.66L4.5 12 15.7.66a2.21 2.21 0 0 1 3.15 0c.87.88.87 2.3 0 3.18L10.79 12l8.06 8.16c.87.88.87 2.3 0 3.18-.44.44-1 .66-1.57.66",
                        },
                      }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showRight
            ? _c(
                "button",
                {
                  class: _vm.$style.right,
                  on: {
                    click: function ($event) {
                      return _vm.scrollAni(1)
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      class: _vm.$style.arrow,
                      attrs: {
                        height: "16",
                        width: "16",
                        viewBox: "0 0 24 24",
                        "aria-label": "Right arrow",
                        role: "img",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M6.72 24c.57 0 1.14-.22 1.57-.66L19.5 12 8.29.66c-.86-.88-2.27-.88-3.14 0-.87.88-.87 2.3 0 3.18L13.21 12l-8.06 8.16c-.87.88-.87 2.3 0 3.18.43.44 1 .66 1.57.66",
                        },
                      }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { ref: "wrapper", class: _vm.$style.wrapper },
            _vm._l(_vm.keywordList, function (item, index) {
              return _c(
                "router-link",
                {
                  key: index,
                  class: _vm.$style.word,
                  style: { backgroundColor: item["dominant_color"] },
                  attrs: {
                    "aria-label": "搜索 " + item.term,
                    title: "搜索 " + item.term,
                    to: { path: "/tupian", query: { kw: item.term } },
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.wrapperClick(item.display)
                    },
                  },
                },
                [
                  _c("span", { class: _vm.$style.text }, [
                    _vm._v(_vm._s(item.display)),
                  ]),
                ]
              )
            }),
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }