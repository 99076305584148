<style lang="scss" module src="./Login.scss">
</style>
<template>
    <div :class="$style.wechatLogin">
        <div :class="$style.qrcodeBox">
            <div ref="qrcodeBox" :class="$style.qrcode">
                <!--微信登录码位置-->
              <canvas ref="qrcode"/>
            </div>
            <div ref="refreshMask" :class="$style.refresh" @click="refresh">刷新</div>
        </div>
        <div :class="[$style.loginDes, 'font-medium']"><span
                :class="$style.wechatIcon"></span><span>微信扫码 - 关注公众号注册</span>
        </div>
      <div @click="goNewLogin" v-if="isShowGoNewLogin" :class="[$style.goLogin,'font-regular']"><span>已有账号？</span><span>直接登录</span></div>
        <div :class="[$style.loginExplain, 'font-regular']">若继续，则表示你同意 {{webSiteName}} 的<a :class="['font-medium']" target="_blank"
                                                                          :href="statementUrl">网站声明</a>
        </div>
    </div>

</template>

<script lang='ts'>
    import {
        CommonLoginAction,
        BigBigWork,
        CreateCrossDomain,
        getDomain,
        getQueryString,
        loginQrcode,
        wechatIsLogin,
        getUrlParam
    } from 'bbw-common'
    import Cookies from "js-cookie";
    import WechatLoginManager from './WechatLoginManager'
    import axios from 'axios'
    import qs from "qs";
    import QRCode, { QRCodeRenderersOptions } from "qrcode" ;
    import {ServerMetaSearch} from "bbw-common/src/constants/servers";

    // await CreateCrossDomain () ;
    interface loginRes {
        expireSeconds: number,
        ticket: string,
        url: string
    }

    export default {
        name: "WechatLogin",
        props: {
            pageUrl: {
                default: '',
                type: String,
                required: false
            },
            statusShow: {
                default: true,
                type: Boolean,
                required: false
            },
            statementUrl: {
                default: `https://www.bigbigwork.com/webstatement.html`,
                type: String,
                required: false
            },

            webSiteName: {
            default: `大作`,
            type: String,
            required: false
            },
          isShowGoNewLogin:{
              default:false,
            type:Boolean,
            required:false
          }
        },
        data: function () {
            return {
                userSource: '',
                qrcode: '',
                loginManager: null,
                timer: null,
                needForceRefresh: false,
                closeTime: null,
            }
        },
        methods: {
          goNewLogin(){
            const redirectUrl = window.location.href
            const pUrl = __DEV__?`//p-test.bigbigwork.com` : `//p.bigbigwork.com`
            window.location.href = `${pUrl}/loginnew.htm?redirect=${encodeURIComponent(redirectUrl)}`;
          },
            getUtmParams() {
                const source = getQueryString(`utm_source`);
                let data = null;
                if (source == null || source == "") {
                    data = {
                        medium: '自然流量'
                    }
                    //如果utm参数，并且是大图页走默认
                    if (!this.userSource) {
                        this.userSource = encodeURIComponent(JSON.stringify({
                            medium: '自然流量',
                            url: window.location.host + window.location.pathname
                        }));
                    }
                } else {
                    data = {
                        source: source,
                        medium: getQueryString(`utm_medium`),
                        term: getQueryString(`utm_term`),
                        campaign: getQueryString(`utm_campaign`),
                        url: '',
                        content: ''
                    };
                    const content = getQueryString(`utm_content`);
                    if (content != null && content != "") {
                        data["content"] = content;
                    }
                }
                if (data == null) {
                    return;
                }
                data.url = window.location.host + window.location.pathname;
                this.userSource = encodeURIComponent(JSON.stringify(data));
                this.CookieHandle().setCookie('jhktracert', JSON.stringify(data), {
                    expires: new Date(new Date().getTime() + 1 * 60 * 60 * 1000),
                    domain: getDomain(location.hostname)
                })
                let loginParams = {
                  login_url: window.location.href,
                  login_web: window.location.host.includes(`bigbigai.com`)? `ai`: `dz`
                }
                this.CookieHandle().setCookie('jhkloginhost', JSON.stringify(loginParams), {
                  expires: new Date(new Date().getTime() + 1 * 60 * 60 * 1000),
                  domain: getDomain(location.hostname)
                })
                /*Cookies.set("jhktracert", JSON.stringify(data), {
                    expires: new Date(new Date().getTime() + 1 * 60 * 60 * 1000),
                    domain: getDomain(location.hostname)
                });*/
            },
            onLoginSuccess(token: string) {
                //  1 检查当前url Redirect 并过滤
                //  2 判断是否以ifream载入
                //  3 模块载入 > 判断是否是bigbigwork
                //   if ()
                console.log('1111111', this.pageUrl)
                CommonLoginAction({redirectUrl: this.pageUrl, token})
            },
            async refresh(forceUpdate = true) {
                await this.$nextTick()
                this.$refs.refreshMask.style.opacity = 0;
                this.getWeixinLogin(this.needForceRefresh);
                if (forceUpdate) {
                    this.needForceRefresh = true
                }
            },
            /* 获取微信登录码 */
            getWeixinLogin(isForceRefresh = false) {
                /* 优先本地缓存 */
                const isGet = this.fetchQrcodeFromCookie(isForceRefresh)
                if (isGet) return
                /* 后台获取 */
                this.fetchQrcode()
            },
            /* cookie中获取微信码 */
            fetchQrcodeFromCookie(isForceRefresh = false) {
                if (this.CookieHandle().getCookie('loginQrcodeUrl') && this.CookieHandle().getCookie('ticket') && this.CookieHandle().getCookie('expireSeconds') && !isForceRefresh) {
                    this.loginManager.ticket = this.CookieHandle().getCookie('ticket');
                    this.loginManager.url = this.CookieHandle().getCookie('loginQrcodeUrl');
                    this.loginManager.expireSeconds = parseInt(this.CookieHandle().getCookie('expireSeconds')) * 1000;
                    console.log('cookie登录码参数', {
                        'ticket': this.loginManager.ticket,
                        'url': this.loginManager.url,
                        'expireseconds': this.loginManager.expireSeconds
                    });
                    this.updateQrCode(this.loginManager.url);
                    this.startCheck();
                    return true
                }
                return false
            },
            /* 后台获取微信码 */
            async fetchQrcode() {
                const {data} = await axios({
                    method: 'POST',
                    url: loginQrcode
                })
                if (data.recode === '200') {
                    const info = data.data as loginRes
                    this.loginManager.ticket = info.ticket
                    this.loginManager.url = info.url
                    this.loginManager.expireSeconds = info.expireSeconds * 1000
                    this.updateQrCode(info.url)
                    /* 开始轮训检查扫码状态 */
                    this.startCheck();
                    let todyTime = new Date()
                    let hours = todyTime.getHours() + (todyTime.getMinutes()) / 60
                    // 距零点相差的时间戳 expires 天数
                    // let useTime = (24 - hours) / 24;
                    let useTime = 15 / 60 / 24; //15分钟有效期
                    this.CookieHandle().setCookie('loginQrcodeUrl', info.url, {
                        expires: useTime,
                        domain: getDomain(location.hostname)
                    })
                    this.CookieHandle().setCookie('ticket', info.ticket, {
                        expires: useTime,
                        domain: getDomain(location.hostname)
                    })
                    this.CookieHandle().setCookie('expireSeconds', info.expireSeconds, {
                        expires: useTime,
                        domain: getDomain(location.hostname)
                    })
                } else {
                    window.clearInterval(this.timer);
                }
            },
            /* 更新显示登录二维码 */
            updateQrCode(url: string) {
              const qrcodeOpts:QRCodeRenderersOptions = {
                errorCorrectionLevel: `L`,
                width: 220,
                margin: 0
              } ;
              QRCode.toCanvas (
                  this.$refs.qrcode,
                  url,
                  qrcodeOpts,
                  function ( error ) {
                    if ( error ) throw error ;
                    console.log ( `支付宝二维码生成成功!` ) ;
                  } ) ;
            },

            /**
             * @description 检查是否已登录
             * */
            startCheck: function () {
                console.log('env', __DEV__)
                let rotationTotalTime = __DEV__ ? 1000 * 30 : 1000 * 60 * 2; // 轮训2分钟停止
                let rotationTime = 0;
                if (this.timer) {
                    window.clearInterval(this.timer);
                }
                this.timer = window.setInterval(() => {
                    //检查过期时间，如果过期就重新获取登陆二维码；
                    rotationTime += 2000;
                    if (rotationTime > rotationTotalTime) {
                        this.needForceRefresh = false;
                        this.$refs.refreshMask.style.opacity = 1;
                        clearInterval(this.timer)
                    }
                    this.loginManager.expireSeconds -= 2000;
                    if (this.loginManager.expireSeconds <= 0 || !this.CookieHandle().getCookie('loginQrcodeUrl')) {
                        window.clearInterval(this.timer);
                        this.getWeixinLogin();
                    } else {
                        //检查是否登陆
                        this.checkIsLogin();
                    }
                }, 2000);
            },
            CookieHandle: function () {
                let prefix = __DEV__ ? 'test-' : ''
                return {
                    setCookie: function (key, value, options) {
                        console.log('setCookie', `${prefix}${key}`, value, options)
                        Cookies.set(`${prefix}${key}`, value, options)
                    },
                    getCookie: function (key) {
                        return Cookies.get(`${prefix}${key}`)
                    },
                    removeCookie: function (key) {
                        Cookies.remove(`${prefix}${key}`, {domain: getDomain(location.hostname)})
                    }
                }
            },
            /**
             * @description 检查是否扫码成功
             * */
            checkIsLogin: async function () {
                if (this.closeTime && new Date().getTime() - this.closeTime > 15 * 1000) {
                    this.$refs.refreshMask.style.opacity = 1;
                    window.clearInterval(this.timer);
                    return;
                }
                let loginUrl = wechatIsLogin;
                loginUrl = loginUrl.replace("{ticket}", this.loginManager.ticket);
                // 来源统计
                let source = getQueryString('source') || '';
                let openurl = decodeURIComponent(window.location.href);
                let data = {};
                source && (data = {source: source})
                /*const reqData = new FormData()
                reqData.append('userSource', this.userSource)
                reqData.append('source', source)
                reqData.append('url', openurl)*/
                const objDatas = {
                    userSource: this.userSource,
                    source: source,
                    url: openurl,
                    inviteCode: ''
                }
                const inviteCode = getUrlParam('inviteCode')
                if (inviteCode) {
                    // reqData.append('inviteCode', inviteCode)
                    objDatas.inviteCode = inviteCode
                }
                const res = await axios({
                    method: "POST",
                    url: loginUrl,
                    data: qs.stringify(objDatas),
                    headers: {
                        'Content-Type': "application/x-www-form-urlencoded; charset=UTF-8"
                    }
                })
                console.log('登录校验结果', res)
                if (res.status === 200 && res.data.recode === '200') {
                    window.clearInterval(this.timer);
                    const token = res.data.data
                    this.CookieHandle().removeCookie('loginQrcodeUrl')
                    this.CookieHandle().removeCookie('ticket')
                    if (/rabbit(-test)?\.bigbigwork\.com/.test(location.href)) {
                        Cookies.set('inviteLoginParams', JSON.stringify({
                            invite_login_page: '大兔登录页',
                            invite_code: getUrlParam('inviteCode') || ''
                        }), {domain: getDomain(), path: `/`, expires: 7})
                    }
                    this.onLoginSuccess(token);
                }
            },
        },
        async mounted() {
            this.getUtmParams()
            this.loginManager = new WechatLoginManager()
            //页面刚开始的加载时候不掉获取二维码接口 等显示弹框的时候再掉
            // this.getWeixinLogin()
        },
        beforeDestroy() {
        },
        watch: {
            statusShow: {
                deep: true,
                immediate: true,
                handler(newV) {
                    if (newV) {
                        this.refresh(false)
                        return
                    }
                    window.clearInterval(this.timer)
                    console.log('action Value:' + newV);
                }
            },
        }
    }
</script>
