<template>
  <transition name="fade">
    <div v-show="show" :class="$style.JHKbombbox">
      <div :class="$style.bombbox" :style=wrapperStyle>
        <span :class="$style.closedthis" @click='close'></span>
        <iframe v-if="showIframe" :src="pageSrc" :class="$style.iframe" :style=wrapperStyle></iframe>
      </div>
    </div>
  </transition>
</template>
<script lang=ts>
  import {BigBigWork, PageNewRegister, ServerMetaSearch} from "bbw-common";

  /** iframe 弹框 */
  export default {
      name:"cDialogIframe",
      props:{
          /** iframe地址 */
          src:{
              type:String,
              required:true
          },
          /** 宽度 */
          width:{
              type:Number,
              required:false,
              default: 400
          },
          /** 高度 */
          height:{
            type:Number,
              required:false,
              default:580
          },
          /** 是否预加载iframe */
          preload:{
            type:Boolean,
              required:false,
              default: true
          },
          /** 是否显示 */
          show:{
              type:Boolean,
              required:false,
              default: false
          },
      },
      beforeMount() {
          BigBigWork.addListener('ShowRegisterDialog', ()=>{
              this.pageSrc = this.src
              this.$emit('update:show', true);
          })
      },
      data(){
          return {
              showIframe: this.preload,
              pageSrc:''
          }
      },
      methods:{
          close(){
              this.pageSrc=''
              this.$emit('update:show', false);
          }
      },
      computed:{
          wrapperStyle(){
              return {
                  width:this.width+'px',
                  height:this.height+'px'
              }
          }
      },
      watch:{
          show: function( newVal ){
              if(newVal === true){
                  this.showIframe = true;
                  window.document.body.style.overflowY = 'hidden';
                  window.document.body.style.paddingRight = '17px';
              }else{
                  window.document.body.style.overflowY = '';
                  window.document.body.style.paddingRight = '';
              }
          }
      }
  }
</script>
<style lang=scss module src="./DialogIframe.scss"></style>
