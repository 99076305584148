<template>
  <div :class="$style.grid" @click.capture.stop="handleClick">
<!--    <a @click.prevent.stop :class="$style.imgHover"> </a>-->
<!--    :href="PagePinliteRegister"-->
    <div :class="$style.btns">
      <div :class="[$style.fav,$style.wateButton]">收藏</div>
      <div :class="[$style.free,$style.wateButton,'font-bold']">
        <svg width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.222 7.93v4.333H1.778V7.93H0v6.067h16V7.93h-1.778zM8 10l3.556-4.167H8.889V0H7.11v5.833H4.444L8 10z" fill-rule="evenodd"/></svg>
        免费下载
      </div>
      <!-- 链接 -->
      <a v-if="item.domain" :class="[$style.link,$style.wateButton]">
        <img :class="$style.linkIcon" :src="`${process.env.CDN_DZ_PATH}/p/pin_link.svg`"/>
        <span :class="$style.linkText"><span>{{item.domain}}</span></span>
      </a>
      <!-- 分享 -->
      <div :class="[$style.share,$style.wateButton]">
        <img :class="$style.shareIcon" :src="`${process.env.CDN_DZ_PATH}/p/pin_share.svg`"/>
      </div>
      <!-- 更多 -->
      <div :class="[$style.more,$style.wateButton]">
        <img :class="$style.shareIcon" :src="process.env.CDN_DZ_PATH+'/p/pin_more.svg'"/>
      </div>
    </div>
    <div :class="$style.imgWrapper" :style="itemStyle">
      <div :class="$style.imgCropper">
        <img
            :alt="item.title"
            :class="$style.img"
            :src="dataSrc"
            @load="handleImageLoaded($event)"
            :style="{opacity:loaded?1:0, 'width': setImgWidth(item)}" />
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
    import {emptyImg } from "@scripts/util";
    import {
      BigBigWork,
      goPinliteRegister,
      goPinliteLogin,
      getPinliteRegister, goLogin, PageSearchIndex
    } from "bbw-common";
    /** 未登录瀑布流item */
    export default {
        name:"GridPinItem",
        props:{
            itemIndex:{
                type:Number,
                required:true
            },
            item:{
                type:Object,
                required:true,
                default:{
                    url:emptyImg,
                    title:""
                }
            },
            /** 是否打开登录弹窗 */
            showRegisterDialog:{
                type:Boolean,
                required:false,
                default: false
            },
            /** 是否为sem_h */
            /*isSemH:{
              type:Boolean,
              required:false,
              default: false
            }*/
        },
        data: function(){
            return {
                CDN_DZ_PATH: process.env.CDN_DZ_PATH,
                dataSrc: this.item.url,
                color: this.item.color,
                loaded: true,
                PagePinliteRegister: getPinliteRegister("/tupian")
            }
        },
        methods:{
          setImgWidth(item) {
            if (item.w >= 236 && item.w < 472){
              return `236px`
            }
            if (item.w < 236) {
              return `${item.w}px`
            }
            return `236px`
          },
            /** 图片加载后调用 */
            handleImageLoaded(e){
                const currentSrc = e.target.src;
                if(currentSrc === emptyImg) return;
                this.loaded = true;
                this.color = 'transparent';
            },
            /** 点击处理 */
            handleClick(e:MouseEvent){
              /*if(this.isSemH){
                goLogin(PageSearchIndex)
                this.showRegisterDialog = false;
              }else{
                goPinliteRegister("/tupian")
              }*/
                if(this.showRegisterDialog){
                    e.stopPropagation();
                    e.preventDefault();
                    // BigBigWork.emit('ShowRegisterDialog');
                    BigBigWork.emit('OpenDialogLogin');
                }else{
                  goPinliteRegister("/tupian")
                }
            }
        },
        computed:{
            itemStyle(){
                return {
                    backgroundColor:this.color
                }
            }
        },
        watch: {
            "item": function(newVal,oldVal){
                if(newVal === oldVal) return;
                let _self = this;
                this.loaded = false;
                this.color = newVal.color;
                this.dataSrc = this.emptyImg;
                //一个microtask后赋值新的图片地址
                Promise.resolve().then(()=>{
                    _self.dataSrc = newVal.url;
                })
            }
        }
    }
</script>

<style lang="scss" module  src="./GridPinItem.scss"></style>
