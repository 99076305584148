<template>
  <div  v-if="$store.state.bannerUnlogin.ready"
        :class="$style.bannerBlock"
        :style="isSemH ? style_h : style"
        @click.capture="handleClick">
    <div :class="$style.banner" :style="isSemH ? style_h : style" ref='topBanner'>
      <a @click.prevent :class="$style.link" :href="isSemH ? $store.state.bannerUnlogin.openUrl_h : $store.state.bannerUnlogin.openUrl"></a>
<!--      <div :class="$style.wrapper">-->
<!--        <div :class='$style.content'>-->
<!--          <span style='color:#333333'>免翻墙无需使用客户端，电脑/手机高效稳定使用</span><img style='margin:0 4px' src='https://dzimg.bigbigwork.com/p/banner_pin_logo.png'/><span style='color:#333333'>Pinterest</span>-->
<!--        </div>-->
<!--        <div :class='$style.buyVip'></div>-->
<!--      </div>-->
    </div>
    <transition name='fadeup'>
      <div v-if="showBottom" :class="[$style.banner,$style.bottom]" :style="isSemH ? style_h : style">
        <a @click.prevent :class="$style.link" :href="isSemH ? $store.state.bannerUnlogin.openUrl_h : $store.state.bannerUnlogin.openUrl"></a>
<!--        <div :class="$style.wrapper">-->
<!--          <div :class='$style.content' v-html='$store.state.bannerUnlogin.content'>-->

<!--          </div>-->
<!--          <div :class='$style.buyVip'></div>-->
<!--        </div>-->
      </div>
    </transition>
  </div>
</template>
<script lang=ts>
  import {BigBigWork, goPinliteRegister,goLogin,PageSearchIndex } from "bbw-common";

  export default {
      name: "BannerUnlogin",
      props:{
          /** 是否打开登录弹窗 */
          showRegisterDialog:{
              type:Boolean,
              required:false,
              default: false
          },
          /** 是否为sem_h */
          isSemH:{
            type:Boolean,
            required:false,
            default: false
          }
      },
      data(){
          return {
              showBottom:false
          }
      },
      activated() {
          this.onScroll();
          window.addEventListener('scroll', this.onScroll)
      },
      deactivated() {
          window.removeEventListener('scroll',this.onScroll);
      },
      methods:{
          onScroll(){
              const domrect = this.$refs.topBanner.getBoundingClientRect();
              if( -domrect.top > 180){
                  this.showBottom = true;
              }else{
                  this.showBottom = false;
              }
          },
          /** 未登录点击输入框处理 */
          handleClick(e:MouseEvent){
            /*if(this.isSemH){
              goLogin(PageSearchIndex)
              this.showRegisterDialog = false;
            }else{
              goPinliteRegister("/tupian")
            }*/
              if(this.showRegisterDialog){
                  e.stopPropagation();
                  e.preventDefault();
                  // BigBigWork.emit('ShowRegisterDialog');
                  BigBigWork.emit('OpenDialogLogin');
              }else{
                goPinliteRegister("/tupian")
              }
          }
      },
      computed:{
          style(){
              return {
                  'background':`url(${process.env.CDN_XCX_PATH}/p/pinlite_unlogin_header.jpg) no-repeat 50% #fff`,
                  'backgroundSize': 'cover'
              }
          },
          style_h(){
            return {
              'background':`url(${process.env.CDN_XCX_PATH}/p/images/banner-v-2.png) no-repeat 50% #fff`,
              'backgroundSize': 'cover'
            }
          }
      }
  }
</script>
<style lang=scss module src="./BannerUnlogin.scss"></style>
