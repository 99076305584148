var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.bannerUnlogin.ready
    ? _c(
        "div",
        {
          class: _vm.$style.bannerBlock,
          style: _vm.isSemH ? _vm.style_h : _vm.style,
          on: {
            "!click": function ($event) {
              return _vm.handleClick.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              ref: "topBanner",
              class: _vm.$style.banner,
              style: _vm.isSemH ? _vm.style_h : _vm.style,
            },
            [
              _c("a", {
                class: _vm.$style.link,
                attrs: {
                  href: _vm.isSemH
                    ? _vm.$store.state.bannerUnlogin.openUrl_h
                    : _vm.$store.state.bannerUnlogin.openUrl,
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fadeup" } }, [
            _vm.showBottom
              ? _c(
                  "div",
                  {
                    class: [_vm.$style.banner, _vm.$style.bottom],
                    style: _vm.isSemH ? _vm.style_h : _vm.style,
                  },
                  [
                    _c("a", {
                      class: _vm.$style.link,
                      attrs: {
                        href: _vm.isSemH
                          ? _vm.$store.state.bannerUnlogin.openUrl_h
                          : _vm.$store.state.bannerUnlogin.openUrl,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }