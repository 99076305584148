var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show",
          },
        ],
        class: _vm.$style.JHKbombbox,
      },
      [
        _c("div", { class: _vm.$style.bombbox, style: _vm.wrapperStyle }, [
          _c("span", {
            class: _vm.$style.closedthis,
            on: { click: _vm.close },
          }),
          _vm._v(" "),
          _vm.showIframe
            ? _c("iframe", {
                class: _vm.$style.iframe,
                style: _vm.wrapperStyle,
                attrs: { src: _vm.pageSrc },
              })
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }